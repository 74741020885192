import React from 'react'
import ContentWrapper from '../styles/ContentWrapper'
import styled from 'styled-components'

const StyledContentWrapper = styled(ContentWrapper)`
    p {
        width: 50%;

        @media (max-width: ${props => props.theme.breakpoints.sm}) {
            width: 100%;
        }
    }
    .has-flex {
        display: flex;
        justify-content: flex-end;
    }
`

export default function Contact(props) {
    return (
        <StyledContentWrapper>
            {props.children}
        </StyledContentWrapper>
    )
}
