import React from "react"

import Layout from "../components/layout"
import Contact from "../components/contact"
import SEO from "../components/seo"
import Footer from "../components/footer"

import remark from 'remark'
import remarkHTML from 'remark-html'

const toHTML = value => remark()
                    .use(remarkHTML)
                    .processSync(value)
                    .toString()

export default function ImprintPage({ data, location }) {
  const post = data.impressum.edges[0].node

  return (
    <Layout>
      <SEO title="Impressum" />
      <Contact>
        <h4>{ post.frontmatter.title }</h4>

        <div
          dangerouslySetInnerHTML={{ __html: toHTML(post.html) }}
        />

      </Contact>
      <Footer></Footer>
    </Layout>
  )
}

export const query = graphql`
  {
    impressum: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/impressum/"}}) {
      edges {
        node {
          frontmatter {
            title
          }
          html
          fields {
            slug
          }
        }
      }
    }
  }
`
